@switch (state()) {
  @case (PROPERTY_STATE.Mediating) {
    <div class="rounded bg-red-500 text-white px-1 inline-block">
      {{ '斡' | translate }}
    </div>
  }
  @case (PROPERTY_STATE.Negotiated) {
    <div class="rounded bg-red-500 text-white px-1 inline-block">
      {{ '定' | translate }}
    </div>
  }
  @case (PROPERTY_STATE.priceOff) {
    <div class="rounded bg-red-500 text-white px-1 inline-block">
      {{ '降' | translate }}
    </div>
  }
}
