<!-- close area -->
<div class="function-container">
  @if (hasData) {
    <span class="info"
      >{{ currentIndex + 1 }}&#160;/&#160;{{ data.length }}</span
    >
  }
  <span class="flex-auto"></span>
  @if (hasData) {
    <i class="material-icons icon scale-100" (click)="toggleZoom()">
      {{ scaling ? 'zoom_out' : 'zoom_in' }}
    </i>
    <i class="material-icons icon" (click)="isAutoPlay = !isAutoPlay">
      {{ isAutoPlay ? 'pause' : 'play_arrow' }}
    </i>
    @if (isCanFull) {
      <i
        class="material-icons icon"
        toggleFullscreen
        (change)="fullscreenChange($event)"
      >
        {{ isFullScreen ? 'fullscreen_exit' : 'fullscreen' }}
      </i>
    }
  }
  <i class="material-icons icon" (click)="closeView()">close</i>
</div>
@if (hasData) {
  <!-- photo area -->
  <div
    #container
    class="show-container overflow-hidden"
    [class.open]="selectorOpen$ | async"
  >
    <ngx-hm-carousel
      #gallery
      [(ngModel)]="currentIndex"
      [autoplay]="!!isAutoPlay"
      [between-delay]="0"
      [drag-many]="false"
      [recognizers]="recognizers"
      [stopPanListener]="scaling"
      align="left"
    >
      <ul ngx-hm-carousel-container class="gallery-container show">
        @for (item of data; track item) {
          <li ngx-hm-carousel-item #galleryItem>
            <ng-container
              *ngTemplateOutlet="
                GalleryTemplate;
                context: {
                  $implicit: item
                }
              "
            >
            </ng-container>
          </li>
        }
      </ul>
      <ng-template #carouselPrev>
        @if (!scaling && (isAutoPlay || currentIndex !== 0)) {
          <i class="material-icons icon">keyboard_arrow_left</i>
        }
      </ng-template>
      <ng-template #carouselNext>
        @if (!scaling && (isAutoPlay || currentIndex !== data.length - 1)) {
          <i class="material-icons icon">keyboard_arrow_right</i>
        }
      </ng-template>
      <ng-template #carouselProgress>
        <div class="progress bg-accent"></div>
      </ng-template>
    </ngx-hm-carousel>
  </div>
  <!-- select area -->
  <div
    class="select-container bg-gray-200/50"
    [class.open]="selectorOpen$ | async"
  >
    <div class="inner">
      <div class="toggle-select bg-gray-200/50" (click)="toggleSelector()">
        <i class="material-icons">view_comfy</i>
      </div>
      <ngx-hm-carousel
        [ngModel]="currentIndex"
        [drag-many]="true"
        [infinite]="false"
        show-num="auto"
        align="center"
      >
        <ul ngx-hm-carousel-container class="gallery-container select">
          @for (item of data; track item; let i = $index) {
            <li
              class="control_pane cursor-pointer"
              ngx-hm-carousel-item
              (click)="currentIndex = i"
            >
              <ng-container
                *ngTemplateOutlet="
                  ItemTemplate;
                  context: {
                    $implicit: item,
                    index: i,
                    currentIndex: currentIndex
                  }
                "
              >
              </ng-container>
            </li>
          }
        </ul>
      </ngx-hm-carousel>
    </div>
  </div>
} @else {
  <div class="nofile">尚未有圖片</div>
}
