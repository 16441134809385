import { Injectable, TemplateRef } from '@angular/core';

import { NgxHmGalleryItem, NgxHmGalleryService } from 'ngx-hm-gallery';
import { Observable } from 'rxjs';

export interface MyGallery {
  open: (currentIndex: number, autoplay?: boolean) => Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class MyGalleryService {
  private itemTemplate: TemplateRef<any>;
  private galleryTemplate: TemplateRef<any>;

  constructor(private _gallery: NgxHmGalleryService) {}

  setTemplate(itemTemplate, galleryTemplate) {
    this.itemTemplate = itemTemplate;
    this.galleryTemplate = galleryTemplate;

    return this;
  }

  create(data: NgxHmGalleryItem[]): MyGallery {
    return {
      open: (currentIndex: number, autoplay = false) => {
        return this._gallery.open({
          data,
          itemTemplate: this.itemTemplate,
          galleryTemplate: this.galleryTemplate,
          autoplay,
          currentIndex,
        });
      },
    };
  }
}
