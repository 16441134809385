@if (property()) {
  <a
    class="relative block bg-white elevation-2 h-full rounded-4xl {{
      activity ? 'mt-12' : 'mb-4'
    }}"
    [ngClass]="classList"
    [externalRouterLink]="['/properties', property().id + '']"
  >
    <article class="relative overflow-hidden h-full flex flex-col rounded-4xl">
      <div class="relative">
        <img
          class="object-cover aspect-video flex-none w-full non-drag"
          [src]="
            property().photo?.file
              | img: 'hq'
              | safe: 'url' : 'assets/img/default_property.png'
          "
          [attr.loading]="'lazy'"
        />
        <span class="absolute right-1 bottom-1 text-xs text-white/70">
          No. {{ property().id }}
        </span>
      </div>
      <div class="flex flex-col flex-auto overflow-hidden">
        <header class="mx-4 text-xs border-bottom pt-3">
          <ul class="mb-1">
            <div
              class="inline-block p-1 bg-accent text-white rounded text-xs float-right"
            >
              {{ property().category?.name }}
            </div>
            @if (isBuilding) {
              <li class="flex flex-wrap gap-1">
                <span class="text-secondary">{{ '格局' | translate }}：</span>
                @for (pattern of property().patterns; track pattern) {
                  @if (pattern.amount > 0) {
                    <span class="whitespace-nowrap inline-block font-bold">
                      {{ pattern.amount
                      }}{{ pattern.type | pattern | translate }}
                    </span>
                  }
                }
              </li>
              <li>
                <span class="text-secondary">{{ '屋齡' | translate }}：</span>
                <span class="font-bold">
                  {{ property().age }}
                </span>
              </li>
            }
            <li>
              @if (property().spaceNumber; as spaceNumber) {
                <span class="text-secondary"
                  >{{ '室內坪數' | translate }}：</span
                >
                <span
                  class="font-bold"
                  [ngClass]="{
                    'mr-2': property().mainSpaceNumber !== undefined
                  }"
                >
                  {{ spaceNumber }}{{ '坪' | translate }}
                </span>
              }
              @if (property().mainSpaceNumber; as mainSpaceNumber) {
                <span class="text-secondary"
                  >{{ '登記坪數' | translate }}：</span
                >
                <span class="font-bold">
                  {{ mainSpaceNumber }}{{ '坪' | translate }}
                </span>
              }
            </li>
          </ul>
        </header>
        <main class="border-top border-bottom mx-4 pt-2 flex-auto bottom-fuzzy">
          <h6 class="overflow-hidden title">
            <app-property-state-tag
              [state]="property().state"
            ></app-property-state-tag>
            @if (property().featured) {
              <div class="rounded bg-accent text-white px-1 inline-block">
                {{ '精選' | translate }}
              </div>
            }
            @if (property().isNew) {
              <div class="rounded bg-primary text-white px-1 inline-block">
                {{ '新' | translate }}
              </div>
            }
            {{ property().name }}
          </h6>
          @if (!noInfo()) {
            <p class="overflow-hidden text-sm text-secondary">
              {{ property().body_short }}
            </p>
          }
        </main>
        <footer class="m-2 mx-4 text-center">
          <p class="flex items-center text-xs mb-1">
            <mat-icon color="primary">location_on</mat-icon>
            <span class="ml-1 text-secondary">
              {{ property().fullAddressShort }}
            </span>
          </p>
          <i class="flex-auto"></i>
          <h4 class="text-accent ml-1">
            $&nbsp;
            <span>
              {{ property().price }}
            </span>
          </h4>
        </footer>
      </div>
      @if (tag) {
        <ngx-symbol-flag
          size="bigger"
          position="left"
          class="text-white z-[3]"
          [ngClass]="property().type === 0 ? 'bg-primary' : 'bg-red-400'"
        >
          <ng-container *ngTemplateOutlet="tag"></ng-container>
        </ngx-symbol-flag>
      }
    </article>
    @if (activity) {
      <div class="tag absolute">
        <h6 class="bg-accent m-0 text-white text-center rounded-2.5xl">
          <ng-container *ngTemplateOutlet="activity"></ng-container>
        </h6>
      </div>
    }
  </a>
}

@if (checked) {
  <ng-container *ngTemplateOutlet="checked"></ng-container>
}
