import { Pipe, PipeTransform } from '@angular/core';

import { PROPERTY_PATTERN } from '@alan-apps/api-interfaces';

@Pipe({
  name: 'pattern',
  standalone: true,
})
export class PatternPipe implements PipeTransform {
  transform(value: PROPERTY_PATTERN, args?: any): any {
    switch (value) {
      case PROPERTY_PATTERN.Room:
        return '房';
      case PROPERTY_PATTERN.Hall:
        return '廳';
      case PROPERTY_PATTERN.Bathroom:
        return '衛';
      case PROPERTY_PATTERN.Balcony:
        return '陽台';
      case PROPERTY_PATTERN.Kitchen:
        return '廚房';
      case PROPERTY_PATTERN.OpenSpace:
        return '開放式空間';
    }
    return null;
  }
}
