import { Injectable } from '@angular/core';

import { NgxRxModalService } from 'ngx-rx-modal';
import { Observable } from 'rxjs';

import { NgxHmGalleryComponent } from './ngx-hm-gallery.component';
import { NgxHmGalleryOption } from './ngx-hm-gallery.model';

@Injectable({
  providedIn: 'root',
})
export class NgxHmGalleryService {
  constructor(private _modal: NgxRxModalService) {}

  open(options: NgxHmGalleryOption): Observable<any> {
    return this._modal.open(NgxHmGalleryComponent, {
      data: options,
      panelStyle: {
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        padding: 0,
        background: 'rgba(0, 0, 0, .9)',
        'border-radius': 'unset',
        'user-select': 'none',
      },
      disableBackdropClick: true,
      disableCloseButton: true,
    });
  }
}
