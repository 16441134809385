<div #containerElm class="carousel">
  <!-- main content -->
  <ng-content select="[ngx-hm-carousel-container]"></ng-content>
  <!-- left -->
  @if (contentPrev) {
    <div #prev class="direction left">
      <ng-container *ngTemplateOutlet="contentPrev"></ng-container>
    </div>
  }
  <!--  right -->
  @if (contentNext) {
    <div #next class="direction right">
      <ng-container *ngTemplateOutlet="contentNext"></ng-container>
    </div>
  }
  <!-- indicators -->
  @if (dotElm) {
    <ul class="indicators">
      @for (dot of itemElms; track dot; let i = $index) {
        <li (click)="currentIndex = i">
          <ng-container
            *ngTemplateOutlet="
              dotElm;
              context: {
                $implicit: {
                  index: i,
                  currentIndex: currentIndex
                }
              }
            "
          >
          </ng-container>
        </li>
      }
    </ul>
  }
  <!-- progress -->
  @if (progressElm && autoplay) {
    <div #progress>
      <ng-container *ngTemplateOutlet="progressElm"> </ng-container>
    </div>
  }

  @if (grabbing) {
    <div class="mask">
      @if (leaveObs$ | async) {}
    </div>
  }
</div>
