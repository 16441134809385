import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Attribute,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { Property } from '@alan-apps/data-access';
import { ExternalRouterLinkDirective, SafePipe } from '@nghedgehog/angular-ui';
import { TranslateModule } from '@ngx-translate/core';

import { SymbolFlagComponent } from '@nghedgehog/angular-ui';

import { LoadingBlockDirective } from '@nghedgehog/angular-ui';

import { ImgPipe } from '../../../../core/pipe/img.pipe';
import { PatternPipe } from '../../../../core/pipe/pattern.pipe';
import { PropertyStateTagComponent } from '../property-state-tag/property-state-tag.component';
import { input } from '@angular/core';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss'],
  standalone: true,
  imports: [
    ExternalRouterLinkDirective,
    NgClass,
    LoadingBlockDirective,
    PropertyStateTagComponent,
    MatIconModule,
    SymbolFlagComponent,
    NgTemplateOutlet,
    TranslateModule,
    PatternPipe,
    SafePipe,
    ImgPipe,
  ],
})
export class PropertyCardComponent implements OnInit {
  @ContentChild('tag') tag: TemplateRef<any>;
  @ContentChild('checked') checked: TemplateRef<any>;
  @ContentChild('activity') activity: TemplateRef<any>;
  classList = 'rounded-4xl relative w-full ' + this.className;

  noInfo = input(false);

  property = input<Property | undefined>(undefined, { alias: 'data' });

  photoUrl: string;
  isBuilding = false;

  constructor(@Attribute('class') private className: string) {}

  ngOnInit(): void {
    if (this.property().category) {
      this.isBuilding = ['house'].includes(this.property().category.id);
    }
  }
}
