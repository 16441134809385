import { Component, Input } from '@angular/core';

import { PROPERTY_STATE } from '@alan-apps/api-interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { input } from '@angular/core';

@Component({
  selector: 'app-property-state-tag',
  templateUrl: './property-state-tag.component.html',
  styleUrls: ['./property-state-tag.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class PropertyStateTagComponent {
  state = input<PROPERTY_STATE | undefined>();

  PROPERTY_STATE = PROPERTY_STATE;
}
