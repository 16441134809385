import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { input } from '@angular/core';

// TODO: ssr problem should not hide on ssr
// TODO: show number change should recalculate is show and init show number
@Directive({
  selector: '[ngxHmCarouselDynamic]',
  standalone: true,
})
export class NgxHmCarouselDynamicDirective {
  index = input<number | undefined>(undefined, {
    alias: 'ngxHmCarouselDynamic',
  });
  length = input(0, { alias: 'ngxHmCarouselDynamicLength' });
  show = input(1, { alias: 'ngxHmCarouselDynamicShow' });
  @Input('ngxHmCarouselDynamicIndex')
  set currentI(value: number) {
    if (!this.completed) {
      const nextI = value + this.show();
      const prevI = value - this.show();
      if (
        this.index() === 0 ||
        this.index() === this.length() - 1 ||
        this.index() === nextI ||
        this.index() === prevI ||
        this.index() === value
      ) {
        this._view.createEmbeddedView(this._template);
        this.completed = true;
      }
    }
  }

  private completed = false;

  constructor(
    private _view: ViewContainerRef,
    private _template: TemplateRef<any>,
  ) {
    this._view.clear();
  }
}
